.table-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .MuiTextField-root,
    .MuiSelect-root {
        margin-right: 16px;

        @media (max-width: 768px) { // Mobile styles
            margin-right: 8px; // Reduce margin for tighter space
            flex: 1; // Allow fields to scale and fit better
        }
    }

    @media (max-width: 768px) {
        flex-wrap: wrap; // Stack controls vertically
        justify-content: center; // Center align for better appearance
    }
}

.data-table {
    &__cell {
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }

        @media (max-width: 768px) {
            font-size: 0.875rem; // Adjust font size for smaller screens
            padding: 8px; // Reduce padding for compactness
        }
    }

    &__link {
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        @media (max-width: 768px) {
            font-size: 0.875rem; // Adjust font size for better readability
        }
    }
}
