// styles.scss

// Variables
$primary-color: white;
$secondary-color: #f4f4f4; // Optional for variety
$text-color: #333; // Text color for contrast

// Mixin for responsive font size
@mixin responsive-font($mobile-size, $desktop-size) {
  font-size: $desktop-size;

  @media (max-width: 768px) {
    font-size: $mobile-size;
  }
}

// Base styles
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: $primary-color;
  color: $text-color;
  line-height: 1.6;

  @include responsive-font(14px, 16px);
}

// Optional utility classes
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

// Responsive adjustments
@media (max-width: 768px) {
  body {
    background-color: $secondary-color; // Slight change for smaller screens
  }
}
