.all-registrations-container {
    margin: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 16px;

    .title {
        text-align: center;
        font-weight: bold;
        margin-bottom: 16px;
    }

    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .search-input {
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 250px;
        }

        .export-btn {
            background-color: #1976d2;
            color: #fff;
            &:hover {
                background-color: #115293;
            }
        }
    }

    .data-table {
        th,
        td {
            padding: 12px;
            text-align: left;
        }

        th {
            background-color: #f5f5f5;
            font-weight: bold;
        }

        .image-thumbnail {
            width: 100px;
            height: 100px;
            cursor: pointer;
            object-fit: cover;
            border: 1px solid #ddd;
            border-radius: 4px;
        }

        .no-data {
            text-align: center;
        }
    }

    .image-preview {
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto;
        border-radius: 8px;
    }
}
