.home-container {
    margin-top: 2%;
    min-width: 100%;
    padding: 16px;

    // Responsive styles
    @media (max-width: 768px) { // For tablets and smaller screens
        padding: 12px;
        margin-top: 4%;
    }

    @media (max-width: 480px) { // For mobile devices
        padding: 8px;
        margin-top: 6%;
    }
}
