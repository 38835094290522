.monthly-summary {
    padding: 20px;

    h4 {
        text-align: center;
        margin-bottom: 20px;
        font-size: 2rem;

        @media (max-width: 600px) {
            font-size: 1.5rem;
        }
    }

    .form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        margin-bottom: 20px;

        .month-picker {
            width: 100%;
            max-width: 300px;
        }

        button {
            width: 100%;
            max-width: 150px;
        }
    }

    .chart-container {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 400px;
        height: auto;

        canvas {
            max-width: 100%; // Fit canvas to container width
            height: auto !important; // Maintain aspect ratio
        }

        @media (max-width: 600px) {
            min-height: 300px;
            overflow-x: auto; // Enable horizontal scrolling for dense labels
        }
    }

    @media (max-width: 600px) {
        padding: 10px;

        .form {
            gap: 10px;

            .month-picker {
                font-size: 0.9rem;
            }

            button {
                font-size: 0.8rem;
            }
        }

        .chart-container {
            canvas {
                font-size: 0.8rem;
            }
        }
    }
}
